import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Input, Grid } from 'antd';
import './styles.less';
import { WTF_API_URL } from '../../utils/constants';
const { useBreakpoint } = Grid;
const { TextArea } = Input;

function ContactUsModule({open, handleClose, api}){
  const screens = useBreakpoint();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  
  const handleSubmit = () => {
    if(name && phone){
      const data = {
        message: 'BHARTH DELIVERY : '+message,
        name: phone,
        phone: name,
      }
      axios.post(`${WTF_API_URL}/contacts`, data)
      .then(() => {
        handleClose();
        setName('');
        setPhone('');
        setMessage('');
        openNotification('topRight')
      })
      .catch(() => {
        openNotification('topRight', 'error')
        handleClose();
      })
    }
  }

  const openNotification = (placement, type) => {
    switch(type) {
      case 'error':
        api.error({
          message: 'Sorry unable to place details now, try again later',
          placement,
        });
        break;
      default:
        api.success({
          message: 'Our sales team will contact you soon',
          placement,
        });
        break;
        
    }
  };

  return (
    <Modal
      title="Contact us"
      visible={open}
      onOk={handleSubmit}
      onCancel={handleClose}
    >
        <Input 
        className={screens.md  ? 'input-text-root' : 'input-text-mobile'} 
        size="large" 
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <br />
      <Input 
        className={screens.md  ? 'input-text-root' : 'input-text-mobile'} 
        size="large" 
        placeholder="Contact Phone" 
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <br />
      <TextArea 
        className={screens.md  ? 'input-text-root' : 'input-text-mobile'} 
        rows={4}
        size="large" 
        placeholder="Message" 
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
    </Modal>
  )
}

export default ContactUsModule;