import React from 'react';
import {Route, Redirect} from 'react-router-dom';

function UserRouteHoc({component: Component, ...rest}) {
  const token = window.localStorage.getItem('user_auth_token');
  const ref = rest.location.pathname.slice(1)+rest.location.search;
  return (
    <Route
      {...rest}
      render={(prop) =>
        token && token !== '' ? (
          <Component {...prop} />
        ) : (
          <Redirect to={`/login?ref=${ref}`} />
        )
      }
    />
  );
}

export default UserRouteHoc;
