import {applyMiddleware, compose, createStore} from 'redux';
import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import reducer from './reducers/index';
import thunk from 'redux-thunk';

/** Configure History */
const history = createBrowserHistory();

/** Store requirements - middleware */
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, routeMiddleware];
const composeEnhancers = compose;

/** Configure Store */
const store = createStore(
  reducer(history),
  composeEnhancers(
    applyMiddleware(...middlewares)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export {store, history};
