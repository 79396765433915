import * as actionTypes from '../../actionTypes/index';

const initialState = {
  merchants: [],
  orders: [],
  preferences: {},
  liveOrders: true,
  orderAnalytics: [],
};

export default function adminReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ADMIN_INIT_MERCHANTS:
      return {
        ...state,
        merchants: action.payload,
      };
    case actionTypes.ADMIN_ADD_MERCHANT:
      return {
        ...state,
        merchants: [...state.merchants, action.payload]
      }
    case actionTypes.ADMIN_UPDATE_MERCHANT:
      let updateMerchant = [...state.merchants];
      let updateMerchantIndex = updateMerchant.findIndex((a) => a._id === action.payload._id);
      if(updateMerchantIndex > -1){
        updateMerchant[updateMerchantIndex] = action.payload;
      }
      return {
        ...state,
        merchants: updateMerchant
      }
    case actionTypes.ADMIN_DELETE_MERCHANT:
      let deleteMerchant = [...state.merchants];
      let deleteMerchantIndex = deleteMerchant.findIndex((a) => a._id === action.payload);
      if(deleteMerchantIndex > -1){
        deleteMerchant.splice(deleteMerchantIndex, 1);
      }
      return {
        ...state,
        merchants: deleteMerchant
      }
    case actionTypes.ADMIN_INIT_ORDERS:
      return {
        ...state,
        orders: action.payload
      }
    case actionTypes.ADMIN_INIT_MERCHANT_PREFERENCES:
      return {
        ...state,
        preferences: action.payload
      }
    case actionTypes.ADMIN_DELETE_MERCHANT_PREFERENCES:
      return {
        ...state,
        preferences: {}
      }
    case actionTypes.SET_ORDER_STATE:
      return {
        ...state,
        liveOrders: action.payload
      }
    case actionTypes.ADMIN_INIT_ORDER_ANALTICS:
      return {
        ...state,
        orderAnalytics: action.payload
      }
    default:
      return state;
  }
}
