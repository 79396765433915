import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.less';
import suspenseHoc from './hoc/suspenseHoc';
import headerHoc from './hoc/headerHoc';
import headerFooterHoc from './hoc/headerFooterHoc';
import UserRouteHoc from './hoc/UserRouteHoc';
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const MerchantPage = lazy(() => import('./pages/MerchantPage'));
const AdminPage = lazy(() => import('./pages/AdminPage'));
const OrderPage = lazy(() => import('./pages/OrderPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage'));
const TrackPage = lazy(() => import('./pages/TrackPage'));
const UserLoginPage = lazy(() => import('./pages/UserLoginPage'));

function App() {
  return (
    <Switch>
      <Route
        exact
        path="/merchant"
        component={suspenseHoc(MerchantPage)}
      />
      <Route
        exact
        path="/admin"
        component={suspenseHoc(AdminPage)}
      />
      <Route
        exact
        path="/login"
        component={suspenseHoc(UserLoginPage)}
      />
      <Route
        exact
        path="/order"
        component={suspenseHoc(headerHoc(OrderPage))}
      />
      <UserRouteHoc
        exact
        path="/profile"
        component={suspenseHoc(headerHoc(ProfilePage))}
      />
      <UserRouteHoc
        exact
        path="/track"
        component={suspenseHoc(headerHoc(TrackPage))}
      />
      <Route
        path="/"
        component={suspenseHoc(headerFooterHoc(DashboardPage))}
      />
    </Switch>
  );
}

export default App;
