import React from 'react';
import { Layout, Button } from 'antd'; 
import './styles.less';
import logo from '../../assets/logo/logo.png';
import CustomFacebook from '../../svgs/CustomFacebook';
// import CustomInstagram from '../../svgs/CustomInstagram';
import CustomLinkedIn from '../../svgs/CustomLinkedIn';


function FooterModule(){
  
  const menu_ui = () => {
    return [
      <Button className='footer-icon' shape="circle" onClick={() => window.open('https://www.facebook.com/bharatdelivery', '_blank')} icon={<CustomFacebook />} />,
      // <Button className='footer-icon' shape="circle" icon={<CustomInstagram />} />,
      <Button className='footer-icon' shape="circle" onClick={() => window.open('http://linkedin.com/company/bharat-delivery', '_blank')}icon={<CustomLinkedIn />} />
    ]
  }
  return (
    <Layout style={{margin: 'auto', maxWidth: '1200px'}}>
      <footer className='footer-root'>
        <div className='footer-flex' style={{flexWrap: 'wrap', alignItems: 'center'}}>
          <div>
            <img width={'128px'} height={'40px'} src={logo} alt="bharat delivery logo" />
          </div>
          <div className={'copyrights-laptop'}>
            2020 &#169; BHARAT DELIVERY
          </div>
          <div>
            {menu_ui()}
          </div>
        </div>
        <div className={'copyrights-mobile'}>
            2020 &#169; BHARAT DELIVERY
          </div>
      </footer>
    </Layout>
  )
}

export default FooterModule;